#d-prescription-background {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1030;
}

#d-prescription-container {
  margin: auto;
  background-color: #ffffff;
  padding: 20px;
  max-width: 1100px;
  margin-top: 60px;
  overflow: auto;
  max-height: calc(100vh - 80px);
  font-size: 14px;
}

.d-prescription-remove-list-button {
  width: 13px;
  display: inline-block;
  font-size: 12px;
  color: inherit;
  line-height: 24px;
  height: 40px;
}

#d-prescription-container h1 {
  font-size: 13px;
  margin-bottom: 5px;
}

#d-prescription-container .d-prescription-description-container > div {
  height: 28px;
  line-height: 25px;
}

#d-prescription-container input[type="radio"] {
  
  display: inline-block;
  width: 30px;
}

#d-prescription-container input,
#d-prescription-container textarea,
#d-prescription-container select {
  display: block;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 3px 5px;
  width: 100%;
  font-size: 16px;
}

#d-prescription-container input[type=checkbox] {
	-moz-appearance:none;
	-webkit-appearance:none;
	-o-appearance:none;
	outline: none;
	content: none;	
  display: inline-block ;
  width: 16px;
  border-width: 0 !important;
  margin-right: 5px;
  margin-left: 0;
  padding-left: 0;
}

#d-prescription-container input[type=checkbox]:before {
    content: "✔";
    font-size: 12px;
    line-height: 13px;
    text-align: center;
    color: transparent !important;
    background: #FFFFFF;
    display: block;
    width: 15px;
    height: 15px;
    border: 1px solid #CCCC;
    border-radius: 3px;
}

#d-prescription-container input[type=checkbox]:checked:before {

	color: #1063B2 !important;
}

#d-prescription-container .d-prescription-input-group > * {
  border-radius: 0;
}

#d-prescription-container .d-prescription-input-group > *:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

#d-prescription-container .d-prescription-input-group > *:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

#d-prescription-container .d-prescription-input-group-h > * {
  display: inline-block;
  border-radius: 0;
}
#d-prescription-container .d-prescription-input-group-h > *:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
#d-prescription-container .d-prescription-input-group-h > *:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

#d-prescription-card-container {
  border: 1px solid #ccc;
  box-shadow: 1px 1px 8px -5px #000;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
}

#d-prescription-save-button,
#d-prescription-close-button,
#d-prescription-add-button {
  background: none;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  padding: 3px 15px;
  font-size: 16px;
  margin-left: 10px;
}

#d-prescription-close-button {
  background: #DB5743;
  color: #FFFFFF;
}


#d-prescription-save-button {
  background: #3366FF;
  color: #FFFFFF;
}

#d-prescription-add-button {
  margin-left: 0;
  font-size: 13px;
  border: 1px solid #1063B2;
  color: #1063B2;
  transition: background .2s;
}
#d-prescription-add-button:hover {
  background-color: #1063B2;
  color: #FFFFFF;
}

#d-prescription-medicine-table {
  margin-top: 20px;;
}

#d-prescription-medicine-table tr th {
  background-color: #1063B2;
  color: #FFFFFF;
  font-size: 12px;
  padding: 2px 12px;
  font-weight: bold;
}

#d-prescription-medicine-table tr th:first-child {
  border-top-left-radius: 5px;
}
#d-prescription-medicine-table tr th:last-child {
  border-top-right-radius: 5px;
}

#d-prescription-medicine-table tr {
  border-left: 1px solid #EEE;
  border-right: 1px solid #EEE;
  border-bottom: 1px solid #EEE;
}
#d-prescription-medicine-table .d-prescription-row-price {
  height: 40px;
  line-height: 26px;
  text-align: right;
}

#d-prescription-medicine-table .d-width-80 {
  width: 80px;
}
#d-prescription-medicine-table .d-width-120 {
  width: 120px;
}

.d-prescription-text-blue {
  color: #1063B2;
  font-weight: normal;
}

.d-input-discount {
  width: 70px !important;
  display: inline-block !important;
  text-align: right;
}

#d-prescription-price-summary-container {
  font-size: 22px;
  margin-bottom: 20px;
  color: #1063B2;
  font-weight: bold;
}

@media only screen and (min-width: 769px) {
  #d-prescription-container {
    font-size: 13px;
  }

  #d-prescription-container input,
  #d-prescription-container textarea,
  #d-prescription-container select,
  #d-prescription-save-button,
  #d-prescription-close-button {
    font-size: 13px;
  }
}

#d-prescription-table {
  min-height: 600px;
}

#d-prescription-table ul {
  padding: 10px;
  border: 1px solid #222;
  border-radius: 5px;
  font-size: 13px;
  margin-top: 10px;
}

#d-prescription-table ul li {
  margin-bottom: 10px;
}

#d-prescription-table ul li:last-child {
  margin-bottom: 0;
}

.d-prescription-table-more-dropdown {
  padding:5px 0 !important;
}

.d-prescription-table-more-dropdown button,
.d-prescription-table-more-dropdown a {
  display: block;
  width: 100%;
  border: 0;
  background: none;
  text-align: left;
  padding: 5px 15px;
  text-decoration: none;
  color: inherit;
}

.d-prescription-table-more-dropdown button:hover,
.d-prescription-table-more-dropdown a:hover {
  background-color: #F1F2F3;
  cursor: pointer;
  text-decoration: none;
  color: #009c9f;
}

.d-prescription-table-more-dropdown hr {
  margin: 5px 0;
}

#d-prescription-table .d-prescription-list-status-container > * {
  display: inline-block;
}

#d-prescription-table .d-prescription-list-status-container > select {
  width: 120px;
}

.d-prescription-link {
  background: none;
  border: none;
  color: #009c9f !important;
  width: 18px;
  height: 18px;
  padding: 2px !important;
  margin: 0px 3px !important;
}

.d-prescription-link:hover {
  color: #3369fe;
}