body {
  background-color: #f5f7fa;
  min-height: 100vh;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
}


h1, .d-font-mon {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#dd-side-container {
  width: 100px;
  min-height: 100vh;
  background-color: #4e73df;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(10%, #4e73df),
    to(#224abe)
  );
  background-image: linear-gradient(180deg, #4e73df 10%, #224abe 100%);
  background-size: cover;
  padding: 15px;
  color: #FFF;
  font-weight: bold;
}

.dd-side-title {
  font-size: 16px;
  text-align: center;
  width: 100px;
  display: none;
}

#dd-side-container a {
  display: inline-block;
  color: #FFF;
  font-weight: normal;
  font-size: 9px;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, sans-serif !important;
  text-align: center;
}

#dd-side-container a i {
  font-size: 16px;
  margin-bottom: 5px;
}

#dd-side-container a:hover {
  text-decoration: none;
  opacity: 0.7;
}

#dd-side-container.dd-side-min a i {
  font-size: 16px;
}

#dd-side-container .dd-hr {
  border-bottom: 1px solid rgba(255,255,255,.15);
  margin-top: 15px;
  margin-bottom: 15px;
}

#dd-side-min-button {
  display: none;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  text-align: center;
  background-color: rgba(255,255,255,.2);
  border: 0;
}

#dd-side-min-button:hover {
  background-color: rgba(255,255,255, 0.5);
}

#dd-menu-mobile-button {
  background-color: #dddfeb;
  border-radius: 50%;
  border:0;
  vertical-align: middle;
  margin-left: 15px;
  height: 40px;
  width: 40px;
  display: inline-block;
}

#dd-side-container.dd-side-hide {
  width: 0;
  overflow: hidden;
  padding: 0;
}

@media (min-width: 960px) {
  
  .dd-side-title {
    display: inline-block;
  }

  #dd-side-min-button {
    display: inline-block;
  }

  #dd-side-container {
    width: 220px;
    transition: width .2s;
  }

  #dd-side-container a {
    font-size: 12px;
    text-align: left;
  }
  
  #dd-side-container a i {
    margin-right: 3px;
  }

  #dd-side-container.dd-side-min {
    width: 120px;
    text-align: center;
  }

  #dd-side-container.dd-side-min .dd-side-title {
    visibility: hidden;
  }

  #dd-side-container.dd-side-min a {
    font-size: 10px;
    text-align: center;
  }

  #dd-side-container.dd-side-min a i {
    margin-right: 0;
  }
}

#dd-top-container {
  background: #FFF;
  box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15);
  padding: 20px;
  vertical-align: middle;
  z-index: 100;
}

#dd-body-container {
  padding: 20px;
  height: calc(100vh - 120px);
  overflow-y: auto;
}

#dd-body-footer {
  line-height: 40px;
  height: 40px;
  background-color: #FFFFFF;
  color: #888;
  font-size: 11px;
}

.dd-shadow {
  box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
}

.dd-card {
  border: 1px solid rgb(227, 230, 240);
  background-color: #FFFFFF;
  border-radius: .35rem;
}

.dd-card-header {
  background-color: #f8f9fc;
  border-bottom: 1px solid #e3e6f0;
  padding: 15px;
  border-top-left-radius: .35rem;
  border-top-right-radius: .35rem;
  color: #4e73df;
  font-weight: bold;
}

.dd-card-small {
  padding: 25px 20px;
  border: 1px solid rgb(227, 230, 240);
  background-color: #FFFFFF;
  border-radius: .35rem;
}

.dd-card-small .dd-card-small-icon {
  font-size: 34px;
  color: #dddfeb;
}

.dd-card-small .dd-card-small-title {
  font-size: 12px;
  margin-bottom: 5px;
  height: 26px;
}

.dd-card-small .dd-card-small-text {
  font-size: 20px;
  font-weight: bold;
}

.dd-card-small-blue {
  border-left: 5px solid #4e73df;
}

.dd-card-small-blue .dd-card-small-title,
.dd-card-small-blue .dd-card-small-icon  {
  color: #4e73df;
}


.dd-card-small-green {
  border-left: 5px solid #1cc88a;
}

.dd-card-small-green .dd-card-small-title,
.dd-card-small-green .dd-card-small-icon {
  color: #1cc88a;
}

.dd-card-small-yellow {
  border-left: 5px solid #f6c23e;
}

.dd-card-small-yellow .dd-card-small-title,
.dd-card-small-yellow .dd-card-small-icon {
  color: #f6c23e;
}